import React from "react";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";

import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Query from "@arcgis/core/rest/support/Query";

import { TFunction } from "react-i18next";

import { StyledSkeletonCard } from "./List-styled";
import { getDistanceFromFocalPoint, roundToTwo, updateDomainFields } from "../../utils/utils";
import { updateOpenNow } from "../LocationCard/OpenNowHelper";

const MIN_LIST_COUNT = 50;

const generateSkeletonCards = () => {
  const skeletonCards = [];
  for (let i = 0; i <= 10; i++) {
    const skeletonCard = (
      <StyledSkeletonCard key={uuidv4() + i}>
        <Skeleton />
        <Skeleton count={2} />
      </StyledSkeletonCard>
    );
    skeletonCards.push(skeletonCard);
  }
  return skeletonCards;
};

const getLocationCount = async (locationsFeatureLayer: FeatureLayer, filterWhere?: string) => {
  const queryOptions = {
    where: filterWhere || "1=1",
  };
  const count = await locationsFeatureLayer.queryFeatureCount(queryOptions);
  return count;
};

const getSomeLocations = async (
  {
    t,
    featureLayer,
    initialBufferDistance,
    openHoursServiceTypes,
    openHoursTimeWindows,
    domainFieldNames,
    filterWhere,
    focalPoint,
  }: {
    t: TFunction;
    featureLayer: FeatureLayer;
    initialBufferDistance: number;
    openHoursServiceTypes?: string[];
    openHoursTimeWindows?: number[];
    domainFieldNames?: string[];
    filterWhere?: string;
    focalPoint?: Graphic;
  },
  minListCount?: number // really only used for testing
) => {
  if (!minListCount) minListCount = MIN_LIST_COUNT;
  const queryOptions = {
    outFields: ["*"],
    where: filterWhere || "1=1",
    returnGeometry: true,
  } as Query;

  let locations: Graphic[] = [];

  if (!focalPoint) {
    queryOptions.num = minListCount;
    const results = await featureLayer.queryFeatures(queryOptions);
    locations = results.features;
  } else {
    const count = await getLocationCount(featureLayer, filterWhere);
    if (count < minListCount) {
      const results = await featureLayer.queryFeatures(queryOptions);
      locations = results.features;
    } else {
      let bufferDist = initialBufferDistance;
      // query with buffer, and continue doubling buffer dist and querying until get the minimum number of features
      while (locations.length < minListCount) {
        const newQueryOptions = JSON.parse(JSON.stringify(queryOptions)); // deep copy of query object, needed for tests
        newQueryOptions.distance = bufferDist;
        newQueryOptions.geometry = focalPoint.geometry;

        const results = await featureLayer.queryFeatures(newQueryOptions);
        locations = results.features;
        bufferDist = bufferDist * 2;
      }
    }
  }

  if (domainFieldNames) {
    updateDomainFields(locations, featureLayer, domainFieldNames);
  }

  if (openHoursServiceTypes && openHoursTimeWindows) {
    updateOpenNow(locations, openHoursServiceTypes, openHoursTimeWindows, t);
  }

  await _sortLocationsByProximity({ focalPoint, locations });
  return locations;
};

const getAllLocations = async (
  t: TFunction,
  locationsFeatureLayer: FeatureLayer,
  openHoursServiceTypes?: string[],
  openHoursTimeWindows?: number[],
  domainFieldNames?: string[],
  filterWhere?: string,
  focalPoint?: Graphic
) => {
  const queryOptions = {
    num: 100,
    outFields: ["*"],
    where: filterWhere || "1=1",
    returnGeometry: true,
  } as Query;

  let exceededTransferLimit = true;
  let startNum = 0;
  let locations: Graphic[] = [];
  while (exceededTransferLimit) {
    const newQueryOptions = JSON.parse(JSON.stringify(queryOptions)); // deep copy of query object, needed for tests
    newQueryOptions.start = startNum;
    const results = await locationsFeatureLayer.queryFeatures(newQueryOptions);
    locations = locations.concat(results.features);
    exceededTransferLimit = results.exceededTransferLimit;
    startNum += 100;
  }

  if (domainFieldNames) {
    updateDomainFields(locations, locationsFeatureLayer, domainFieldNames);
  }

  if (openHoursServiceTypes && openHoursTimeWindows) {
    updateOpenNow(locations, openHoursServiceTypes, openHoursTimeWindows, t);
  }

  await _sortLocationsByProximity({ focalPoint, locations });
  return locations;
};

const _sortLocationsByProximity = async ({
  focalPoint,
  locations,
}: {
  focalPoint?: Graphic;
  locations: Graphic[];
}) => {
  if (focalPoint && locations?.length) {
    // Get the distances of each feature from the focal point
    locations.forEach((location: Graphic) => {
      //* NOTE: in theory the following should work for calculating distances in miles, but initial tests sometimes showed potentially incorrect ascending ordering.
      const distance = getDistanceFromFocalPoint(focalPoint, location);
      location.attributes.proximity = roundToTwo(distance);
    });
    // Sort locations by proximity in ascending order (true refers to whether order should be ascending), while moving locations with null values to end of array so that they don't display early on in the List due to showing 0.0 mi.
    locations.sort(_moveNullsToEndOfArr(true, "proximity"));
  }
};

// Sort the array of features by distance (ascending)
const _moveNullsToEndOfArr = (ascending: boolean, attr: string) => {
  return (a: Graphic, b: Graphic) => {
    const aAttr = a.attributes[attr];
    const bAttr = b.attributes[attr];
    // equal items sort equally
    if (aAttr === bAttr) {
      return 0;
    }
    // nulls sort after anything else
    else if (aAttr === null) {
      return 1;
    } else if (bAttr === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (ascending) {
      return aAttr < bAttr ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
      return aAttr < bAttr ? 1 : -1;
    }
  };
};

export { generateSkeletonCards, getSomeLocations, getAllLocations, getLocationCount };
