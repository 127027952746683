import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { CategoryFilterValue, CategoryFilterItem } from "../Filter.types";
import CodedValueDomain from "@arcgis/core/layers/support/CodedValueDomain";

const _getCategoryFilterValues = ({
  filters,
  layer,
}: {
  filters: CategoryFilterItem[];
  layer: FeatureLayer | undefined;
}) => {
  const filterFields = filters.flatMap((filter) => {
    return filter.filter_fields;
  });

  const results: CategoryFilterValue[] = [];
  // loop over all the fields used in the filter
  filterFields.forEach((fieldName) => {
    filters.forEach((filter) => {
      const dataLabel = filter?.dataLabels[fieldName];
      if (dataLabel) {
        // if dataLabel type is not string it's the label
        if (typeof dataLabel === "string") {
          let value = fieldName;
          if (
            filter.filter_type === "checkbox-multi-field" &&
            filter.filter_fields_values?.length
          ) {
            const fieldIndex = filter.filter_fields.indexOf(fieldName);
            if (fieldIndex !== -1) {
              value = filter.filter_fields_values[fieldIndex];
            }
          }
          results.push({
            value: value,
            label: dataLabel,
            groupLabel: filter.filter_label,
            fieldName,
            checked: false,
            toolTip:
              filter?.dataToolTips && filter?.dataToolTips[fieldName]
                ? filter.dataToolTips[fieldName]
                : null,
          });
        } else {
          // if dataLabel is an object then keys are field names and values are labels
          Object.keys(dataLabel).forEach((value) => {
            const label: string = dataLabel[value];
            results.push({
              value: Number.isNaN(Number(value)) ? value : Number(value), // test for number
              label: label,
              groupLabel: filter.filter_label,
              fieldName,
              checked: false,
            });
          });
        }
      }
    });
  });
  if (layer?.loaded) {
    const filtered = results.filter((item) => {
      try {
        const domain = layer.getFieldDomain(item.fieldName);
        if (!domain) {
          console.log("no domain");
          return true;
        }
        if (domain.type === "coded-value") {
          const codedValueDomain = domain as CodedValueDomain;
          const match = codedValueDomain.codedValues.find(
            (cv) => String(cv.code).toLowerCase() === String(item.value).toLowerCase()
          );
          return !!match;
        }
        return true;
      } catch (err) {
        console.error(err);
        return true;
      }
    });
    return filtered;
  }

  return results;
};

const _getFieldNotNullCategoryFilterWhereClause = (selected: CategoryFilterValue[]) => {
  const clauses = selected.map((s) => `${s.fieldName} <> ''`);
  if (clauses?.length === 1) {
    return clauses[0];
  } else if (clauses?.length > 1) {
    return clauses.join(" OR ");
  }
};

const _getCategoryFilterWhereClause = (selected: CategoryFilterValue[]) => {
  const formatWhereClauseValues = (arr: (string | number)[]) => {
    return arr
      .map((cat) => {
        return typeof cat === "string" ? `'${cat}'` : cat;
      })
      .join(",")
      .toString();
  };

  const fieldNames = Array.from(new Set(selected.map((s) => s.fieldName)));

  const clauses = fieldNames
    .map((fieldName) => {
      const selectedValues = selected.filter((s) => s.fieldName === fieldName).map((s) => s.value);
      const group = selected.filter((s) => s.fieldName === fieldName)[0];
      if (!selectedValues || selectedValues.length === 0) {
        return undefined;
      } else {
        return {
          group: group.groupLabel,
          vals: `${fieldName} IN (${formatWhereClauseValues(selectedValues)})`,
        };
      }
    })
    .filter((c) => !!c);

  const groups = Array.from(new Set(clauses.map((clause) => clause?.group)));

  const whereAry: string[] = [];
  const finalWhere = groups.reduce((finalClause, group) => {
    const wheresToOrJoin = clauses.filter((clause) => clause?.group === group).map((x) => x?.vals);
    whereAry.push(wheresToOrJoin.join(" OR "));
    return whereAry.join(" AND ");
  }, "");

  return finalWhere;
};

const _sortItems = <T>(items: T[], field: keyof T) => {
  return items.sort((a, b) => {
    const itemA = a[field];
    const itemB = b[field];

    const numRegex = /\d/;

    // type checking needed for typescript to allow "toString"
    if (typeof itemA === "string" && typeof itemB === "string") {
      const valA = itemA.toString().toUpperCase();
      const valB = itemB.toString().toUpperCase();

      if (numRegex.test(itemA) && numRegex.test(itemB)) {
        const numA = Number(valA.split(" ")[1]);
        const numB = Number(valB.split(" ")[1]);
        if (numA < numB) return -1;
        if (numA > numB) return 1;
      } else {
        if (valA < valB) return -1;
        if (valA > valB) return 1;
      }
    }
    return 0;
  });
};

export {
  _getCategoryFilterValues,
  _getCategoryFilterWhereClause,
  _getFieldNotNullCategoryFilterWhereClause,
  _sortItems,
};
